import React from 'react';
import './LanguageSwitcher.scss';
import PropTypes from 'prop-types';


const LanguageSwitcher = ( { language, changeLanguage } ) => {
    const languageArray = ['en', 'no', 'ua', 'ru'];
    return (
        <div className="language-switcher">
            {languageArray.map(item =>
                <div key={item}
                     className={language !== item ? (`flag flag__${item}`) : (`flag flag__active flag__${item}`)}
                     onClick={() => changeLanguage(item)}
                />
            )}
        </div>
    )
};

LanguageSwitcher.propTypes = {
    language: PropTypes.string.isRequired,
    changeLanguage: PropTypes.func.isRequired,
};

LanguageSwitcher.defaultProps = {
    language: 'en',
};

export default LanguageSwitcher;
