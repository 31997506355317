import AnswerScreen from './AnswerScreen';
import {connect} from 'react-redux';
import {getQuestionItem} from '../module/actions';

const mapStateToProps = state => ({
    questionsItem: state.app.questionsItem,
});

const mapDispatchToProps = {
    getQuestionItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerScreen);
