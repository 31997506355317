import constants from './constants';

export const initialState = {
    error: null,
    isFetching: false,
    isPasswordRestore: false,
};

function password(state = initialState, action) {
    switch (action.type) {
        case constants.POST_NEW_PASSWORD_REQUEST: {
            return {
                ...state,
                isFetching: true,
                isPasswordRestore: false,
            }
        }

        case constants.POST_NEW_PASSWORD_SUCCESS: {
            return {
                ...state,
                isPasswordRestore: true,
                isFetching: false,
            }
        }

        case constants.POST_NEW_PASSWORD_FAIL: {
            return {
                ...state,
                error: action.payload.message,
                isFetching: false,
            }
        }


        default:
            return state;
    }
}

export default password;

