import RestorePassword from './RestorePassword';
import { connect } from 'react-redux';
import { passRecoveryRequest } from '../module/actions';

const mapStateToProps = state => ({
    error: state.password.error,
    isFetching: state.password.isFetching,
    isPasswordRestore: state.password.isPasswordRestore,
});

const mapDispatchToProps = {
    passRecoveryRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestorePassword);
