import React, {Component} from 'react';
import './AnswerScreen.scss'


class AnswerScreen extends Component {

    componentDidMount() {
        const {getQuestionItem, match: {params: id}} = this.props;
        getQuestionItem(id);
    }


    render() {
        const {questionsItem} = this.props;
        return (
            <div className="answer-container">
                <span className="questions-text">{questionsItem.question}</span>
                <span className="answer-text">{questionsItem.answerText}</span>
            </div>

        );
    }
}


export default AnswerScreen;
