import { combineReducers } from 'redux';
import app from './app/components/FAQ/module/reducer';
import password from './app/components/RestorePassword/module/reducer';
import { i18nState } from "redux-i18n"

const reducer = combineReducers({
    app,
    password,
    i18nState,
});

export default reducer;
