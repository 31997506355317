import React, {Component} from 'react';
import AnswerScreen from './components/FAQ/AnswerScreen'
import RestorePassword from "./components/RestorePassword/RestorePassword";
import '../assets/styles/general.scss'
import {Route, Switch} from 'react-router-dom';


class App extends Component {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path='/faq/:id'
                    component={AnswerScreen}
                />
                <Route
                    exact
                    path='/password_reset/'
                    component={RestorePassword}
                />
            </Switch>
        );
    }
}

export default App;
