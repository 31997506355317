import constants from './constants';

export const initialState = {
    error: null,
    isFetching: false,
    questionsItem:{}
};

function app(state = initialState, action) {
    switch (action.type) {
        case constants.GET_QUESTIONS_ITEM_REQUEST: {
            return {
                ...state,
                isFetching: true,
            }
        }

        case constants.GET_QUESTIONS_ITEM__SUCCESS: {
            return {
                ...state,
                questionsItem: action.payload,
                isFetching: false,
            }
        }

        case constants.GET_QUESTIONS_ITEM__FAIL: {
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            }
        }


        default:
            return state;
    }
}

export default app;

