export const translations = {
    'en': {
        'info_restore': 'Enter your new password. Min 8 characters, 1 letter and 1 number',
        'info_restore_success': 'Password successfully changed',
        'pass_placeholder': 'Password',
        'confirmPass_placeholder': 'Confirm password',
        'error_pass': 'Password is invalid',
        'error_confirmPass': 'Confirm password is invalid',
        'error_match': 'Password don\'t match',
        'error_server': 'Something went wrong please try again later',
        'submit': 'Submit',
    },
    'no': {
        'info_restore': 'Skriv inn det nye passordet ditt. Minst 8 tegn, 1 bokstav og 1 tall',
        'info_restore_success': 'Passordet ble endret',
        'pass_placeholder': 'Passord',
        'confirmPass_placeholder': 'Bekreft passord',
        'error_pass': 'Passordet er ugyldig',
        'error_confirmPass': 'Bekreft at passordet er ugyldig',
        'error_match': 'Passord stemmer ikke overens',
        'error_server': 'Noe gikk galt. Prøv igjen senere',
        'submit': 'å sende',
    },
    'ua': {
        'info_restore': 'Введіть новий пароль. Мінімум 8 символів, 1 літера та 1 число',
        'info_restore_success': 'Пароль успішно змінено',
        'pass_placeholder': 'Пароль',
        'confirmPass_placeholder': 'Підтвердьте пароль',
        'error_pass': 'Пароль недійсний',
        'error_confirmPass': 'Підтвердження пароля недійсне',
        'error_match': 'Пароль не збігається',
        'error_server': 'Щось пішло не так, будь ласка, спробуйте пізніше',
        'submit': 'Відправити',
    },
    'ru': {
        'info_restore': 'Введите новый пароль. Мин 8 символов, 1 буква и 1 цифра',
        'info_restore_success': 'Пароль успешно изменен',
        'pass_placeholder': 'Пароль',
        'confirmPass_placeholder': 'Подтвердите пароль',
        'error_pass': 'Неправильный пароль',
        'error_confirmPass': 'Подтверждение пароля не есть действительным',
        'error_match': 'Пароль не совпадает',
        'error_server': 'Что-то пошло не так. Пожалуйста, повторите попытку позже',
        'submit': 'Отправить',
    },

}
