import React, { Component } from 'react';
import './RestorePassword.scss';
import Input from './input/input';
import LanguageSwitcher from '../../../commonComponents/LanguageSwitcher/LanguageSwitcher';
import Loader from '../../../commonComponents/Loader/Loader'
import { makeFieldValidation } from '../../../../assets/utils/utils';
import { validationPatterns } from '../../../../assets/constans';
import PropTypes from 'prop-types';
import queryString from 'query-string';


class RestorePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            password: '',
            confirmPassword: '',
            passwordValidation: null,
            confirmPasswordValidation: null,
        };
    }

    componentDidMount() {
        const token = queryString.parse(this.props.location.search).token;
        this.setState({token})
    }

    onFieldChange = (e) => {
        return this.setState({[e.target.name]: e.target.value})
    };


    validateField = (name, e) => {
        const { password, confirmPassword } = this.state;
        let passwordValidation;
        let confirmPasswordValidation;

        switch (name) {
            case 'password':
                passwordValidation = makeFieldValidation(password, validationPatterns.password, `${this.context.t('error_pass')}`);
                this.setState({ passwordValidation });
                break;
            case 'confirmPassword':
                confirmPasswordValidation = makeFieldValidation(confirmPassword, validationPatterns.password, `${this.context.t('error_confirmPass')}`);
                if (confirmPasswordValidation === null) {
                    confirmPasswordValidation = this.comparePasswords(password, confirmPassword);
                }
                this.setState({ confirmPasswordValidation });
                break;
            default:
                e.preventDefault();
                passwordValidation = makeFieldValidation(password, validationPatterns.password, `${this.context.t('error_pass')}`);
                confirmPasswordValidation = makeFieldValidation(confirmPassword, validationPatterns.password, `${this.context.t('error_confirmPass')}`);
                if (confirmPasswordValidation === null) {
                    confirmPasswordValidation = this.comparePasswords(password, confirmPassword);
                }
                this.setState({
                    passwordValidation,
                    confirmPasswordValidation,
                }, () => this.onPassRecoverySend());
        }
    };

    comparePasswords = (first, second) => {
        if (first !== second) {
            return `${this.context.t('error_match')}`
        }
        return null
    };

    onPassRecoverySend = () => {
        const { password, token, passwordValidation, confirmPasswordValidation } = this.state;
        const { passRecoveryRequest } = this.props;

        if (passwordValidation === null && confirmPasswordValidation === null) {
            passRecoveryRequest({token, password});
        }
    };


    render() {
        const { token, passwordValidation,  confirmPasswordValidation } = this.state;
        const { error, isFetching, isPasswordRestore } = this.props;
        const formFieldsArray = [
            {fieldName: 'password', fieldPlaceholder: `${this.context.t('pass_placeholder')}*`, error: passwordValidation},
            {fieldName: 'confirmPassword', fieldPlaceholder: `${this.context.t('confirmPass_placeholder')}*`, error: confirmPasswordValidation}
            ];
        const formFields = formFieldsArray.map((item) => {
            return (
                <Input
                    key={item.fieldName}
                    name={item.fieldName}
                    placeholder={item.fieldPlaceholder}
                    onFieldChange={this.onFieldChange}
                    value={this.state[item.fieldName]}
                    error={item.error}
                    validateField={this.validateField}
                />
            )
        });

        return (
            <section className="restore-password">
                {isFetching && <Loader/>}
                <LanguageSwitcher/>
                <div className="restore-password__logo"/>
                {!isPasswordRestore ? (
                    <>
                        <p className="restore-password__info">
                            {this.context.t('info_restore')}
                        </p>
                        <form className="restore-password__form">
                            {formFields}
                            <input
                                className="restore-password__btn"
                                type="submit"
                                onClick={(e) => this.validateField('all', e)}
                                value={this.context.t('submit')}
                                disabled={!token}
                            />
                            {error &&
                            <div className="error-wrap">
                                <p className="error-message">
                                    {this.context.t('error_server')}
                                </p>
                            </div>
                            }
                        </form>
                    </>
                ) : (
                    <p className="restore-password__info">
                        {this.context.t('info_restore_success')}
                    </p>
                    )}
            </section>

        );
    }
}

RestorePassword.propTypes = {
    error: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    isPasswordRestore: PropTypes.bool.isRequired,
    passRecoveryRequest: PropTypes.func.isRequired,
};

RestorePassword.defaultProps = {
    isFetching: false,
    isPasswordRestore: false,
};

RestorePassword.contextTypes = {
    t: PropTypes.func.isRequired,
};

export default RestorePassword;



