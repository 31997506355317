import LanguageSwitcher from './LanguageSwitcher';
import { connect } from 'react-redux';
import { changeLanguage } from '../module/actions';

const mapStateToProps = state => ({
    language: state.i18nState.lang,
});

const mapDispatchToProps = {
    changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
