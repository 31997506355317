import constants from './constants';
import HTTP from '../../../../assets/utils/api';


export const getQuestionItem = (data) => dispatch => {
    dispatch({
        type: constants.GET_QUESTIONS_ITEM_REQUEST,
    });
    return HTTP.get(`/api/faq/${data.id}`, data)
        .then((res) => {
            dispatch({
                type: constants.GET_QUESTIONS_ITEM__SUCCESS,
                payload: res.data,
            })
        })
        .catch((err) => {
            dispatch({
                type: constants.GET_QUESTIONS_ITEM__FAIL,
                payload: err,
            });
        })
};
