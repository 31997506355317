import React, { Component } from 'react';
import classnames from 'classnames';
import './input.scss';
import PropTypes from 'prop-types';


class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false,
        }
    }

    toggleVisibleOfPassword = () => {
        const {
            isPasswordVisible,
        } = this.state;
        return this.setState({
            isPasswordVisible: !isPasswordVisible,
        })
    };

    render() {
        const {
            placeholder,
            error,
            value,
            name,
            onFieldChange,
            validateField,
        } = this.props;
        const { isPasswordVisible } = this.state;
        return (
            <div className={classnames(
                {'input-wrap': !error},
                {'input-wrap error': error}
            )}>
                <span className="icon-lock"/>
                <input
                    className={classnames(
                        {'error': error}
                    )}
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    onChange={onFieldChange}
                    onBlur={() => validateField(name)}
                />
                <span onClick={this.toggleVisibleOfPassword}
                      className={classnames(
                                {'icon-eye visible': isPasswordVisible},
                                {'icon-eye': !isPasswordVisible}
                                )}
                />
                <span className="input-error">{error}</span>
            </div>
        )
    }
}

Input.propTypes = {
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.string,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    validateField: PropTypes.func.isRequired,
};

export default Input;
