import constants from './constants';
import HTTP from '../../../../assets/utils/api';


export const passRecoveryRequest = (data) => dispatch => {
    dispatch({
        type: constants.POST_NEW_PASSWORD_REQUEST,
    });
    return HTTP.post(`/api/password_reset/confirm/`, data)
        .then((res) => {
            dispatch({
                type: constants.POST_NEW_PASSWORD_SUCCESS,
            })
        })
        .catch((err) => {
            dispatch({
                type: constants.POST_NEW_PASSWORD_FAIL,
                payload: err,
            });
        })
};
