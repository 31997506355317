import React from 'react';
import {render} from 'react-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import {routerMiddleware} from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {Router,} from 'react-router-dom';
import I18n from 'redux-i18n';
import {translations} from "./assets/translations/translations";
import reducer from './reducers';
import App from './app/App';
export const history = createBrowserHistory();
const initialState = {};
const enhancers = [];
const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
];

if (process.env.NODE_ENV !== 'production') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export const store = createStore(
    reducer,
    initialState,
    composedEnhancers
);

render(
    <Provider
        store={store}
    >
        <I18n translations={translations} initialLang="en">
            <Router
                history={history}
            >
                <App />
            </Router>
        </I18n>
    </Provider>,
    document.getElementById('root')
);
